<template>
  <div id="uploadImg">
    <el-upload
      style="margin-top:10px"
      accept="image/jpeg,image/png"
      action=""
      list-type="picture-card"
      multiple
      :auto-upload="false"
      :limit="6"
      :on-exceed="handleExceed"
      :on-preview="handlePictureCardPreview"
      :on-change="handleChange"
    >
      <i class="el-icon-plus"></i>
    </el-upload>
    <el-dialog :visible.sync="dialogVisible">
      <img width="100%" :src="dialogImageUrl" />
    </el-dialog>
    <p class="tips">
      <span>*</span>
      提示：最多上传6张图片，支持JPG,JPEG,PNG格式
    </p>
  </div>
</template>
<script>
export default {
  data() {
    return {
      dialogImageUrl: "",
      dialogVisible: false
    };
  },
  methods: {
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    handleExceed(files, fileList) {
      this.$message.error({
        offset: 300,
        message: `当前限制选择 6 个文件，本次选择了 ${
          files.length
        } 个文件，共选择了 ${files.length + fileList.length} 个文件`
      });
    },
    handleChange(file, fileList) {
      this.$emit("imgFileList", fileList);
    }
  }
};
</script>
<style lang="less">
.tips {
  width: auto;
  margin: 10px auto 0;
  font-size: 14px;
  color: #777777;
  span {
    color: red;
    padding-right: 6px;
  }
}
</style>
