<template>
  <div id="participate">
    <div class="map">
      <participatemap
        ref="map"
        v-on:Address="AddressEvent"
        v-on:Latitude="LatitudeEvent"
        v-on:Longitude="LongitudeEvent"
        a-map-key="7611f567fdc3d9fb52b80f0dc85e7236"
        :zoom="12"
        :address="address"
        :center="[lng, lat]"
      />
    </div>
    <!-- 标题 -->
    <div class="public-item title">
      <div class="box">
        <p class="public-title">标题：</p>
        <div class="main">
          <input type="text" placeholder="请输入标题" v-model="title" />
        </div>
      </div>
    </div>
    <!-- 位置 -->
    <div class="public-item position">
      <div class="box">
        <p class="public-title">位置：</p>
        <div class="main">
          <input
            type="text"
            placeholder="请点击地图中的精确位置"
            v-model="address"
            @keyup.enter="searchAddress"
          />
        </div>
      </div>
    </div>
    <!-- 污染类别 -->
    <div class="public-item category">
      <div class="box">
        <p class="public-title">污染类别：</p>
        <div class="main">
          <label
            v-for="(item, index) in alltype"
            :key="index"
            class="public-radio"
          >
            <input
              type="radio"
              name="category"
              :value="item.ID"
              v-model="TypeID"
            />
            <span v-text="item.Title"></span>
          </label>
        </div>
      </div>
    </div>
    <!-- 详情描述 -->
    <div class="public-item info">
      <div class="box">
        <p class="public-title">详情描述：</p>
        <div class="main">
          <el-input
            type="textarea"
            placeholder="描述此污染的具体信息"
            v-model="info"
            maxlength="300"
            show-word-limit
            resize="none"
            :rows="4"
          >
          </el-input>
        </div>
      </div>
    </div>
    <!-- 图片上传 -->
    <div class="public-item photo">
      <div class="box">
        <p class="public-title">上传图片：</p>
        <div class="main">
          <uploadImg v-on:imgFileList="getImgFileList" />
        </div>
      </div>
    </div>
    <p class="public-btn" @click="submit">提交</p>
    <!-- 提交成功弹层 -->
    <div v-if="isSubmitSuccess" class="bind-success">
      <div class="main">
        <div class="center">
          <img src="../../assets/51.jpg" />
          <p class="p1">恭喜您，提交成功</p>
          <p class="p2">
            <span>扫码关注绿领环保公众号，实时接收进展信息</span>
          </p>
          <router-link class="public-btn" to="/">我知道了</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import participatemap from "../../components/participateMap";
import uploadImg from "../../components/uploadImg";
import { formDataAxios } from "@/axios-init";
export default {
  data() {
    return {
      title: "",
      address: "",
      lat: "117.210103",
      lng: "39.135973",
      info: "",
      TypeID: "",
      alltype: [],
      ImgFileList: [],
      isSubmitSuccess: false
    };
  },
  components: {
    participatemap,
    uploadImg
  },
  methods: {
    getImgFileList(data) {
      this.ImgFileList = data;
    },
    searchAddress() {
      this.$refs.map.geoCode();
    },
    AddressEvent(data) {
      this.address = data;
    },
    LatitudeEvent(data) {
      this.lat = data;
    },
    LongitudeEvent(data) {
      this.lng = data;
    },
    submit() {
      if (this.title === "") {
        this.$message.error("标题不能为空!");
        return;
      }
      if (this.address === "") {
        this.$message.error("位置信息不能为空!");
        return;
      }
      if (this.TypeID === "") {
        this.$message.error("请先选择污染类别!");
        return;
      }
      if (this.info === "") {
        this.$message.error("详情描述不能为空!");
        return;
      }
      if (!this.ImgFileList.length) {
        this.$message.error("图片不能为空!");
        return;
      }

      let formData = new FormData();
      formData.append("Account", this.$store.state.user.account);
      formData.append("Title", this.title);
      formData.append("ReportInfo", this.info);
      formData.append("TypeID", this.TypeID);
      formData.append("Address", this.address);
      formData.append("Latitude", this.lat);
      formData.append("Longitude", this.lng);
      formData.append("isPC", "pc");
      this.ImgFileList.forEach(f => formData.append("Image", f.raw));
      formDataAxios
        .post("report/api/add", formData)
        .then(response => {
          if (response.data.state) {
            this.isSubmitSuccess = true;
          } else {
            this.$message.error(response.data.message);
          }
        })
        .catch(error => this.$message.error(error));
    }
  },
  mounted() {
    // 获取污染类型
    this.$http
      .get("report/api/getalltype")
      .then(response => {
        if (response.data.state) {
          this.alltype = response.data.data;
        }
      })
      .catch(function(error) {
        console.log(error);
      });
    this.Address;
  }
};
</script>

<style lang="less" scoped>
#participate {
  width: 1200px;
  margin: 18px auto 0;
  .map {
    width: 100%;
    height: 380px;
  }
  .public-item {
    width: 100%;
    margin-top: 24px;
    display: flex;
    justify-content: flex-end;
    .box {
      width: calc(~"100% - 32px");
      .public-title {
        font-size: 16px;
        color: #408c72;
      }
      .main {
        margin-top: 12px;
      }
    }
    &.title {
      background: url("../../assets/26.png") 0px 2px no-repeat;
    }
    &.position {
      background: url("../../assets/27.png") 0px 0px no-repeat;
    }
    &.category {
      background: url("../../assets/28.png") 0px 0px no-repeat;
    }
    &.info {
      background: url("../../assets/29.png") 0px 0px no-repeat;
    }
    &.photo {
      background: url("../../assets/30.png") 0px 4px no-repeat;
    }
    &.title input,
    &.position input,
    &.info textarea {
      width: 100%;
      box-sizing: border-box;
      padding-left: 10px;
      padding-right: 10px;
      font-size: 14px;
      border: 1px solid #dadadb;
      background-color: #f8f9fd;
      &::-webkit-input-placeholder {
        color: #c7c7c7;
      }
      &::-moz-placeholder {
        /* Mozilla Firefox 19+ */
        color: #c7c7c7;
      }
      &:-moz-placeholder {
        /* Mozilla Firefox 4 to 18 */
        color: #c7c7c7;
      }
      &:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: #c7c7c7;
      }
    }

    &.title input,
    &.position input {
      height: 30px;
    }
    &.category {
      .main {
        display: flex;
      }
    }
    &.info textarea {
      height: 88px;
      resize: none;
      padding-top: 10px;
    }
  }
  /* 公共弹层样式 开始 */
  .bind-success {
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    left: 0;
    top: 0;
    z-index: 99999;
    display: flex;
    justify-content: center;
    align-items: center;
    .main {
      width: 600px;
      height: 346px;
      background-color: #fff;
      .center {
        width: 514px;
        margin: 0 auto;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        img {
          margin: 30px auto 10px;
        }
        p {
          width: 100%;
        }
        .p1 {
          text-align: center;
          font-size: 26px;
          color: #000000;
        }
        .p2 {
          text-align: center;
          margin-top: 10px;
          padding-left: 18px;
          span {
            font-size: 18px;
            color: #000000;
            position: relative;
            &::before {
              content: "";
              display: inline-block;
              width: 5px;
              height: 5px;
              border-radius: 50%;
              background-color: #000;
              position: absolute;
              left: -14px;
              top: 10px;
            }
          }
        }
        .public-btn {
          background-color: #0cc093;
        }
      }
    }
  }
  /* 公共弹层样式 结束 */
}
</style>
