<template>
  <div id="a-map-container"></div>
</template>

<script>
let AMap;
let AMapUI;
let disableNextPicker = false;
export default {
  name: "AMap",
  props: {
    center: Array,
    zoom: Number,
    aMapKey: String,
    address: String
  },
  mounted() {
    let jsapi = document.createElement("script");
    jsapi.src = `https://webapi.amap.com/maps?v=1.4.15&key=${this.aMapKey}&callback=onAMapLoaded`;
    document.head.appendChild(jsapi);

    window.onAMapLoaded = () => {
      let jsapi1 = document.createElement("script");
      jsapi1.src = `//webapi.amap.com/ui/1.0/main.js`;

      jsapi1.onload = function() {
        AMap = window.AMap;
        AMapUI = window.AMapUI;
        this.map = new AMap.Map("a-map-container", {
          zoom: this.zoom,
          resizeEnable: true
        });
        AMap.plugin(
          ["AMap.ToolBar", "AMap.Geolocation"],
          function() {
            this.map.addControl(new AMap.ToolBar());
            var geolocation = new AMap.Geolocation({
              enableHighAccuracy: true, //是否使用高精度定位，默认:true
              timeout: 10000, //超过10秒后停止定位，默认：5s
              buttonPosition: "RB", //定位按钮的停靠位置
              buttonOffset: new AMap.Pixel(10, 20), //定位按钮与设置的停靠位置的偏移量，默认：Pixel(10, 20)
              zoomToAccuracy: true //定位成功后是否自动调整地图视野到定位点
            });
            this.map.addControl(geolocation);
            geolocation.getCurrentPosition(
              function(status, result) {
                if (status == "complete") {
                  this.onComplete(result);
                } else {
                  this.onError();
                }
              }.bind(this)
            );
          }.bind(this)
        );
        this.updateCenter();
        AMapUI.loadUI(
          ["misc/PositionPicker"],
          function(PositionPicker) {
            var positionPicker = new PositionPicker({
              mode: "dragMarker",
              map: this.map,
              iconStyle: {
                url: "//webapi.amap.com/ui/1.0/assets/position-picker2.png",
                ancher: [24, 40],
                size: [48, 48]
              }
            });
            positionPicker.on(
              "success",
              function(positionResult) {
                if (disableNextPicker) {
                  disableNextPicker = false;
                  return;
                }
                this.$emit("Address", positionResult.address);
                this.$emit("Latitude", positionResult.position.lat);
                this.$emit("Longitude", positionResult.position.lng);
              }.bind(this)
            );
            positionPicker.on("fail", function() {
              console.log("拖拽选址失败");
            });
            positionPicker.setMode("dragMap");
            positionPicker.start();
          }.bind(this)
        );
      }.bind(this);
      document.head.appendChild(jsapi1);
    };
  },
  methods: {
    geoCode() {
      var that = this;
      var geocoder = new AMap.Geocoder();
      var address = that.address;
      geocoder.getLocation(address, function(status, result) {
        if (status === "complete" && result.geocodes.length) {
          var lnglat = result.geocodes[0].location;
          that.$emit("Latitude", lnglat.lat);
          that.$emit("Longitude", lnglat.lng);
        } else {
          that.$message.error("地址查询失败");
        }
      });
    },
    //解析定位结果
    onComplete(data) {
      this.$emit("Address", data.formattedAddress);
      this.$emit("Latitude", data.position.lat);
      this.$emit("Longitude", data.position.lng);
    },
    //解析定位错误信息
    onError() {
      console.log("定位失败");
      this.$emit("Address", "天津市河北区天津站");
      this.$emit("Latitude", "39.135973");
      this.$emit("Longitude", "117.210103");
    },
    updateCenter() {
      if (this.map) {
        this.map.setCenter(this.center);
      }
    }
  },
  watch: {
    center() {
      this.updateCenter();
      disableNextPicker = true;
    }
  }
};
</script>

<style scoped>
#a-map-container {
  height: 100%;
  width: 100%;
}
</style>
